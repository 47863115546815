<template>
    <div class="home" >
      <div class="login">
          <div class="auth-wrap">
            <p class="title">보안인증</p>
              <p>현재 지정되지 않은 IP로 접속 중입니다. <br>핸드폰 번호로 인증번호를 보냈으니 <br>SMS를 확인 후 인증번호를 입력해 주세요.</p>
              <div class="auth-form">
                  <input type="text" v-model="login.uid" readonly/>
              </div>
              <div class="auth-form">
                <input class="input_text float-left " type="text"  v-model="login.access_code"  placeholder="인증번호 6자리 입력"/>
                <button  @click="login.doSubmit" class="input_btn float-right">인증번호 입력하기</button>
              </div>
              <button class="auth_btn_re" @click="login.reAuthSend" >인증번호 다시받기</button>
          </div>
      </div>
    </div>
</template>
<script>
// @ is an alias to /src 
import {onMounted, reactive} from 'vue'
import {useRouter} from 'vue-router';
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js';
import { useStore } from 'vuex'

export default {
    components: {
    },
    setup() {
        const router = useRouter();
        const toast  = useToast();
        const store = useStore();
        const login = reactive({
            idx:'',
            uid : "",
            pwd:"",
            access_code : "",
            check:'N',
            auth:'N',
            info:{},

            doSubmit : () => {
                let params = {
                    uid : login.uid,
                    pwd : login.pwd,
                    access_code : login.access_code
                };

                axios.post("/rest/mypims/checkAuthNum", params).then((res) => {
                    if( res.data.err == 0 ){
                      login.auth='Y';
                      store.commit('setUserInfo', login.info);
                      store.commit('setAuth');
                      router.push({
                        name : 'main'
                      });
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },
            reAuthSend : () => {
            let params = {
              uid : login.uid
            };

            axios.post("/rest/mypims/setAuthNum", params).then((res) => {

              if( res.data.err == 0 ){
                toast.success("인증번호발송");
                login.info = res.data;
              } else {
                if(res.data.err_msg) toast.error(res.data.err_msg);
              }
            });
          },
            doEnter : () => {
                login.doSubmit();
            },
        });
        onMounted(() => {
          // Mounted
          console.log("authLogin");
          login.idx = store.state.userLoginIdx;
          login.uid = store.state.userLoginUid;
          login.pwd = 0;

          if ( login.idx == 0) {
            router.back(-1);
          }
          login.reAuthSend();

        });

        return {login};
    }
}
</script>

<style lang="scss" scoped>
body{
    overflow: hidden;
}
</style>